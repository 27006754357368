<template>
    <div>
      
  
     
  
      <!-- Dashboard -->
      <section @submit.prevent="add" class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Application Details</h3>
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box p-3">
                  <div class="widget-content">
                    <form class="default-form">


<div class="container mt-4">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#contact_details" type="button" role="tab">Contact Details
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#student_information" type="button" role="tab">Student's Information</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#language_proficiency" type="button" role="tab">Language Proficiency</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#home_address" type="button" role="tab">Home Address Details
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab">Postal Address Details
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab">Education Background
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab">Work Exprience
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab">Family Contact
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab">Financial Supporter
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab">Contact case Emergency
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab">Declaration
            </button>
        </li>
    </ul>

    <div class="tab-content mt-3" id="myTabContent">
        <div class="tab-pane fade show active" id="contact_details" role="tabpanel">
            <h5>Contact Details</h5>

            <div v-if="conatct_details" class="mt-3" >

              <div><strong> Email  :  </strong> {{conatct_details.email}}</div>
              <div><strong> Phone  :  </strong> {{conatct_details.phone}}</div>
              <div><strong> We chat :  </strong> {{conatct_details.wechat}}</div>



            </div>


        </div>
        <div class="tab-pane fade" id="student_information" role="tabpanel">
          <h5>Student Information</h5>

          <div v-if="students_information" class="mt-3" >

<div><strong> First Name  :  </strong> {{students_information.first_name}}</div>
<div><strong> Middle Name  :  </strong> {{students_information.middle_name}}</div>
<div><strong> Nationality :  </strong> {{students_information.nationality}}</div>
<div><strong> Religion :  </strong> {{students_information.religion}}</div>
<div><strong> Gender :  </strong> {{students_information.gender}}</div>
<div><strong> Maritial Status :  </strong> {{students_information.marital_status}}</div>
<div><strong> Stay in China :  </strong> {{students_information.check_china}}</div>
<div><strong> Have Alcohol :  </strong> {{students_information.check_alcohol}}</div>



</div>


        </div>
      
        <div class="tab-pane fade" id="language_proficiency" role="tabpanel">
          <h5>Language Proficiency</h5>

          <div v-if="language_proficiency" class="mt-3" >

<div><strong> Native Language  :  </strong> {{language_proficiency.native_language}}</div>
<div><strong> English Level  :  </strong> {{language_proficiency.english_level}}</div>
<div><strong> English Certificate  :  </strong> {{language_proficiency.english_certificate}}</div>
<div><strong> Chiness level :  </strong> {{language_proficiency.chinese_level}}</div>


</div>


        </div>
        <div class="tab-pane fade" id="postal_address" role="tabpanel">
          <h5>Postal Address</h5>

          <div v-if="postal_address" class="mt-3" >

<div><strong> Country  :  </strong> {{postal_address.country}}</div>
<div><strong> City  :  </strong> {{postal_address.city}}</div>
<div><strong> District  :  </strong> {{postal_address.district}}</div>
<div><strong> Street :  </strong> {{postal_address.street}}</div>
<div><strong> postal_code :  </strong> {{postal_address.postal_code}}</div>


</div>


        </div>
      
    </div>
</div>

                     
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
    
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "EmployerPostJobView",
  
    data() {
      return {
        application  : {},
        application_id : null ,

        conatct_details : {},
        students_information : {},
        language_proficiency : {},
        postal_address : {},
        home_address : {},
        education_background : {},
        work_experience : {},
        family_conatct : {},
        fiancial_supporter : {},
        conatact_case_emergency : {},
        declaration : {},
        documents : [],
      };
    },
  
    methods: {


    getApplication(){


        axios.get("api/admin/application/show/"+this.application_id).then((res)=>{
  
        this.application = res.data.data;
        if(this.application){

          if( this.application.conatct_details != null){
            this.conatct_details = JSON.parse(this.application.conatct_details);
          }

          if( this.application.students_information != null){
            this.students_information = JSON.parse(this.application.students_information);
          }

          if( this.application.language_proficiency != null){ 
            this.language_proficiency = JSON.parse(this.application.language_proficiency);
          }

          if( this.application.postal_address != null){
            this.postal_address = JSON.parse(this.application.postal_address);
          }

          if( this.application.home_address != null){
            this.home_address = JSON.parse(this.application.home_address);

          }

          if( this.application.education_background != null){
            this.education_background = JSON.parse(this.application.education_background);
          }

          if( this.application.work_experience != null){  
            this.work_experience = JSON.parse(this.application.work_experience);  
          }

          if( this.application.family_conatct != null){
            this.family_conatct = JSON.parse(this.application.family_conatct);  
          }

          if( this.application.fiancial_supporter != null){
            this.fiancial_supporter = JSON.parse(this.application.fiancial_supporter);
          }

          if( this.application.conatact_case_emergency != null){
            this.conatact_case_emergency = JSON.parse(this.application.conatact_case_emergency);
          }

          if( this.application.declaration != null){
            this.declaration = JSON.parse(this.application.declaration);
          }

          if( this.application.documents != null){
            this.documents = JSON.parse(this.application.documents);
          }


        }



     }).catch((error)=>{
      console.error(error.response)
      })

},

  


  
  
  

    },
    mounted: function () {
    this.application_id = this.$route.query.id;
    if(this.application_id != null){
      this.getApplication();
    }
    
    },
  };
  </script>
  
  
  
  <style>
  .button_plus {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    cursor: pointer;
    border: 2px solid #095776;
    display: flex;
  
    /* Mittig */
    top: 44%;
    left: 90%;
  }
  
  .button_plus:after {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #095776;
    top: 50%;
    left: 50%;
  }
  
  .button_plus:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #095776;
    height: 50%;
    width: 4px;
  }
  
  .button_plus:hover:before,
  .button_plus:hover:after {
    background: #fff;
    transition: 0.2s;
  }
  
  .button_plus:hover {
    background-color: #095776;
    transition: 0.2s;
  }
  </style>