<template>
    
    <div>


      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>

      
  
   <!-- Dashboard -->
      <section class="user-dashboard">

   
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Manage Application</h3>
        
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box">
                  <div class="widget-title">
                    <h4>Applications</h4>
  
                    <!-- <div class="chosen-outer">
                      <select class="chosen-select">
                        <option>Last 6 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 16 Months</option>
                        <option>Last 24 Months</option>
                        <option>Last 5 year</option>
                      </select>
                    </div> -->
                  </div>
  
                  <div class="widget-content">
                    <div class="table-outer">
                      <table class="default-table manage-job-table">
                        <thead>
                          <tr>
                            <th>Name</th>
                           
                              <th>Phone</th>
        
                              <th>Country</th>
                              <th>Program</th>
                              <th>Course</th>
                              <th>University</th>
                            <th>Action</th>
                          </tr>
                        </thead>
  
                        <tbody>
                          <tr v-for=" application in applications" :key="application.id"  >
                            <td>
                              <h6 v-if ="application.user.student">{{ application.user.student.full_name }}</h6>
                             
                            </td>
                            <td>
                              <h6 v-if ="application.user.student">{{ application.user.student.phone_number }}</h6>
                             
                            </td>
  
                            <td>
                              <h6 v-if ="application.user.student">{{ application.user.student.country }}</h6>
                             
                            </td>
                            <td>
                              <h6 v-if ="application.program">{{ application.program.name }}</h6>
                             
                            </td>
                            <td>
                              <h6 v-if ="application.course">{{ application.course.title }}</h6>
                             
                            </td>
  
                            <td>
                              <h6 v-if ="application.university">{{ application.university.name }}</h6>
                             
                            </td>
  
                      
                           
                
                            <td>
                              <div class="option-box">
                                <ul class="option-list">
                                  <li><button @click.prevent="viewApplication(application.id)" data-text="Reject Aplication"><span class="la la-eye"></span></button></li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                         
                       
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
  
  <!-- End Dashboard -->
  
  
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
  
  
      data(){
        return{
  
         applications:[],
  
  
        }
  
      },
  
  
  methods: {
    
  
    getApplication() { axios.get("api/admin/application/list",
  
     ).then( res => {
  
    
       this.applications=res.data.data
  
       if(res.status==200){
  
        console.log("Data received")
     
  
       }
     
     }
  
   )
  .catch(error => {
  console.log(error.response)
  });
   
  },


  viewApplication(id) {
    this.$router.push({ name: "admin-application-view", query: { id: id } });
  },



  deletecity(id){
    axios
    .delete("api/admin/city/" + id)
    .then((res) => {
      if (res.status == 200) {
        this.$emit("get_message", res.data.message);
        this.getCity();
      }
    })
    .catch((error) => {
      console.log(error.response);
     
    });
  
  
  },

},
  
  mounted:function(){
    this.getApplication()
  }
  
    
  }
  </script>
  
  <style>
  
  </style>