<template>

  <div  style="margin-top: 50px; padding: 50px;">

    <div class="row">

      <div class="col-lg-3 p-3 fiter-box">

        <h6 class="">Filter programms</h6>

        <h6 class="mt-3">Degree</h6>


        <select  v-model="program_id" class="form-select mt-3" aria-label="Default select example" @change="filter">


        <option disabled value="null">Select a option</option>

<option v-for="degree in degrees" :key="degree.id" :value="degree.id">{{ degree.name }}</option>
</select>




        <h6 class="mt-3">Teaching Language</h6>


        <select v-model="teaching_language" class="form-select mt-3" aria-label="Default select example" @change="filter">


          <option disabled value="null">Select a language</option>
                            <option  value="english">English</option>
                            <option  value="chinese">Chinese</option>
        </select>


        <h6 class="mt-3">Mejor/Subject</h6>


        <select v-model="mejor" class="form-select mt-3" aria-label="Default select example" @change="filter">


          <option disabled value="null">Select a option</option>
                            <option  value="english">IT</option>
                            <option  value="chinese">Business</option>
        </select>


        <h6 class="mt-3">University</h6>


        <select v-model="university_id"     class="form-select mt-3" aria-label="Default select example" @change="filter">


          <option disabled value="null"> Select a option</option>

     <option v-for="list in ulist" :key="list.id" :value="list.id">{{ list.name }}</option>
        </select>


        <h6 class="mt-3">Intake</h6>


        <select v-model="degree" class="form-select mt-3" aria-label="Default select example" @change="byPrograms($event.target.value)">


          <option disabled value="null">Select a option</option>

<option v-for="degree in degrees" :key="degree.id" :value="degree.id">{{ degree.name }}</option>
        </select>



        <h6 class="mt-3">Provice</h6>


        <select class="form-select mt-3" aria-label="Default select example" @change="byPrograms($event.target.value)">


          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>


        
        <h6 class="mt-3">City</h6>


        <select class="form-select mt-3" aria-label="Default select example" @change="byPrograms($event.target.value)">


          <option selected>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>







      </div>


      
      <div class="col-lg-9 ps-4">

        {{ courses.length }}


        <div  class="mt-3" >


          <span class="fiter-tab">Our Top Picks</span>
          <span class="fiter-tab">Most Popular</span>
          <span class="fiter-tab">Fastest Admissions</span>
          <span class="fiter-tab">Highest Rating</span>
          <span class="fiter-tab">Top Ranked</span>



          <div v-for="course in courses" :key="course.id" class="programs-card p-3"> 
            <div class="row">

              <div class="col-lg-1">

                <img class="p-2" src="https://www.malishaedu.com/upload/university/18783303731731476533university_logo.webp" height="90x" width="90px"  alt="">




              </div>


              <div class="col-lg-8 p-2">

                <h5>{{course.title}}</h5>

                <div class="mt-3">

                  <div v-if="course.university" >

                    <span  class="fs-80" >  <i class="fa-solid fa-school"></i>  {{ course.university.name }}  </span>    

<span class="fs-80" >  <i class="fa-solid fa-location-dot"></i>  {{ course.university.city }}  </span>    





                  </div>

                  <div class="mt-2" >

                    <span class="fs-80" >  <i class="fa-solid fa-school"></i>  {{ course.teaching_language }}   </span>    

<span class="fs-80" >  <i class="fa-solid fa-location-dot"></i>  Rank : 123  </span>    





                  </div>


                  <div class="mt-3" >

                    <div class="row">

                      <div class="col-lg-4">

                       <span class="fs-80" >  Tutions fees (Yearly)</span>
                       <h4 v-if="course.fee" class="text-green" >  {{ course.fee }} </h4>
                       <h4 v-else-if="course.program.name=='Bachelor'" class="text-green" >  {{ course.university.bachelor_fee }} </h4>
                       <h4 v-else-if="course.program.name=='Masters'" class="text-green" >  {{ course.university.Masters }} </h4>




                      </div>
                      <div class="col-lg-4">

                        <span class="fs-80" >  Accommodation (Yearly) </span>

                       <h4  class="text-green" >  {{ course.university.accommodation_fee }} </h4>




                      </div>
                      <div class="col-lg-4">

                       <span class="fs-80" >  Duration </span>

                       <h4 class="text-green" >  {{ course.duration }} Year</h4>




                      </div>



                    </div>

                    



</div>

            

                </div>





                  </div>

                  <div style="position: relative;" class="col-lg-3 p-2">

           <span style="position: absolute;right: 0;top: -10px;background-color: green;padding: 5px 10px;color: white;border-radius: 5px;" > {{ course.program.name }}</span>

<p>Intake</p>
March-2025

<p>Application Deadline</p>
20 August,2025
<br>
<button @click="apply(course.id,course.program_id,course.university_id)" class="btn btn-success btn-sm mt-3" >Apply Now !</button>
</div>


   




  </div>


          </div>

        


      



        </div>
  



</div>


      



    </div>

  </div>
 
    
  </template>
  
  <script>
  import axios from "axios";
  import vcookie from "vue-cookie";
  import Constant from "@/interceptor/Constant.js";



  export default {
    data() {
      return {
        
        courses: [],
        university_id : null,
      university_name :"",
      imageurl : Constant.filebaseurl+"/image/university/",
     teaching_language:null,
      program_id:null,
      degrees: [],
      degree: null,
      mejor :null,
      search_key :null,
      ulist:[]
        
      };
    },
  
    methods: {
      allcourses() {
       
      },

      getDegree() {
        axios
          .get("api/web/degree")
          .then((res) => {
           
              this.degrees = res.data.data;
  
            
          })
          .catch((error) => {
            console.log(error.response);
          });
        
      },
      unlist() {
      axios
        .get("api/web/university/list")
        .then((res) => {
         
            this.ulist = res.data.data;

          
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

      byPrograms(id) {
        axios
          .get("api/web/course/program/"+id)
          .then((res) => {
           
              this.courses = res.data.data;
  
            
          })
          .catch((error) => {
            console.log(error.response);
          });
      },

      filter(){

        axios
          .post("api/web/course/filter",{
            key : this.search_key,
            program_id : this.program_id,
            teaching_language : this.teaching_language,
            mejor : this.mejor,
            university_id : this.university_id
            

          })
          .then((res) => {
           
              this.courses = res.data.data;
  
            
          })
          .catch((error) => {
            console.log(error.response);
          });



      },
  
  
 
      
      apply(id,program_id,university_id){

        if(vcookie.get("user") != null){

          if(JSON.parse(vcookie.get("user")).role == "student"){

        axios.post("api/web/application",{
        user_id : JSON.parse(vcookie.get("user")).id,
        university_id : university_id,
        course_id : id,
        program_id : program_id,
        description : "description"


       }).then((res) => {

        if(res){
          // this.$emit("get_message", "Applied to Course Successfully");
             
          this.$router.push({ name: "application" , query: {  application_id: res.data.data.id } });

        }
       
       }).catch((error) => {
         console.log(error.response);
       });




            
          }else{

          var route_name_stu = this.$route.name

       

           this.$router.push({ name: "login" , query: { route_name: route_name_stu, id: this.university_id } });



          }


      
          
        }else{

          var route_name = this.$route.name



       

this.$router.push({ name: "login" , query: { route_name: route_name, id: this.university_id } });


       

        }


  
     
      },
  
  
    
    },
  
    mounted: function () {

      var university_id = this.$route.query.id;
      this.university_id = university_id;
      this.university_name = this.$route.query.name;
      var program_id = this.$route.query.program_id;
      var key = this.$route.query.key;
      if (program_id != null) {
        this.program_id = program_id
      }
      if (key != null) {
        this.search_key = key
      }

      this.allcourses();
      this.getDegree();
      this.unlist();
      this.filter();
    },
  };
  </script>
  
  <style>
  </style>