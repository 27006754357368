
<template>
  <div class="login-section">
    <div
      class="image-layer"
      style="background-image: url(frontend/images/background/12.jpg)"
    ></div>
    <div class="outer-box">
      <!-- Login Form -->
      <div class="login-form default-form">
        <div class="form-inner">
          <h3 class="text-center">Create an Account</h3>

          <!--Login Form-->
          <form id="sign-up-form" @submit.prevent="signUp">
            <div class="row">
              <div class="form-group col-12">
                <label>Full name</label>
                <input
                  id="full_name"
                  type="text"
                  @input="clearError('full_name')"

                  v-model="full_name"
                  placeholder="Full Name"
                />
              
              </div>

              <div class="form-group col-2">
                <label>Code</label>

                <select id="code" class="form-select" v-model="code" aria-label="Default select example">
                  <option value="+88">+88</option>

                </select>


              
               
              </div>

              <div  class="form-group col-10">
                <label>Phone number</label>
                <input
                  id="phone_number"
                  type="number"
                  @input="clearError('phone_number')"
                  v-model="phone_number"
                  placeholder="Phone Number"
                />
              
              </div>

              <div class="form-group col-4">
                <label>Gender</label>

                <select id="gender" v-model="gender" class="form-select" aria-label="Default select example">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>

                </select>


              
               
              </div>


              <div class="form-group col-8">
                <label>Country</label>

                <select id="country" v-model="country" class="form-select" aria-label="Default select example">
                  <option value="China">China</option>
                  <option value="Bangladesh">Bangladesh</option>

                </select>


              
               
              </div>

            </div>

            <div class="form-group">
              <label>Email </label>
              <input id="email"                    @input="clearError('email')"
              type="email"  v-model="email" placeholder="Email" />
            
            </div>
            <div class="password-group">
              <label>Password</label>
              <p class="input-group">
                <input
                  @input="clearError('password')"
 
                  id="password"
                  v-model="password"

                  class="custom-input"
                  placeholder="Password"
                  type="password"
                  size="30"
                  aria-required="false"
                />
                <span
                  class="input-group-addon"
                  role="button"
                  title="veiw password"
                  id="passBtn"
                >
                  <i class="bi bi-eye-slash" aria-hidden="true"></i>
                </span>
              </p>
             
            </div>

            <div class="password-group mt-2">
              <label> Confirm Password</label>
              <p class="input-group">
                <input
                 @input="clearError('password_confirmation')"

                  id="password_confirmation"
                  v-model="password_confirmation"
                  class="custom-input"
                  placeholder="Confirm password"
                  type="password"
                  size="30"
                  aria-required="false"
                />
                <span
                  class="input-group-addon"
                  role="button"
                  title="veiw password"
                >
                  <i class="bi bi-eye-slash" aria-hidden="true"></i>
                </span>
              </p>
          
            </div>

            <input  type="checkbox" value="Terms and condition"  id="terms" /> Terms and condition


    
            <div id="loader-small">
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
            </div>

            <div class="form-group mt-3">
              <button
                class="theme-btn btn-style-one"
                type="submit"
                name="Register"
              >
                REGISTER
              </button> 
            </div>
          </form>

          <div
            id="otpbox"
            style="border-radius: 10px"
            class="p-3 border text-center"
          >
            <h5 class="text-success">
              We send you a OTP to your mail ! Check your mail
            </h5>

            <form @submit.prevent="emailverification">
              <div class="row mt-3">
                <div class="col-9">
                  <div class="form-group">
                    <input
                      id="password-field"
                      v-model="otp"
                      type="password"
                      placeholder="Enter OTP"
                    />
                    <p v-if="otperror != ''" class="mt-1 text-danger">
                      {{ otperror }}
                    </p>
                  </div>
                </div>
                <div class="col-3 p-1">
                  <button class="btn btn-lg btn-primary">
                    <i class="las la-angle-double-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>



          <div
            id="loginbox"
            style="border-radius: 10px"
            class="p-3 border text-center"
          >
            <h5 class="text-success mb-3">
              Your email is already Registered !
            </h5>

            <a href="/login" class="theme-button p-2 mt-3">
                   Login now !
            </a>
                
          </div>

          <!-- <div class="bottom-box">
            <div class="divider"><span>or</span></div>
            <div class="btn-box row">
              <div class="col-lg-6 col-md-12">
                <a href="#" class="theme-btn social-btn-two facebook-btn"><i class="fab fa-facebook-f"></i> Log In via Facebook</a>
              </div>
              <div class="col-lg-6 col-md-12">
                <a href="#" class="theme-btn social-btn-two google-btn"><i class="fab fa-google"></i> Log In via Gmail</a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!--End Login Form -->
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { Validator } from "@/other/Validator";
import $ from "jquery";



export default {
  name: "RegisterView",
  data() {
    return {
    
      code: "+88",
      gender :"Male",
      country : "China",
      
    
      
    };
  },
  methods: {
      signUp() {

        var loader = document.getElementById("loader-small");

        var validator = new Validator();



        var error = validator.validated([
        { field: "full_name", value: this.full_name, type: "required" }, 
        { field: "phone_number", value: this.phone_number, type: "required" }, 
        { field: "email", value: this.email, type: "required" }, 
        { field: "password", value: this.password, type: "required" }, 
        { field: "password_confirmation", value: this.password_confirmation, type: "required" }, 

       


      ]);

      if (error) {

        loader.style.display = "none";


       }else{

        axios
          .post("api/auth/register", {
            name: this.full_name,
            code: this.code,
            phone_number: this.phone_number,
            gender: this.gender,
            country: this.country,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            role :"student"
          
          })
          .then((res) => {
            if (res) {
              this.$router.push({ name: "login" });
            
            }
          })
          .catch((error) => {

            if (error.response.data.errors.email) {
              $("#email").addClass('error-input')
              $("#email").val("")

              $("#email").attr("placeholder", error.response.data.errors.email[0]);
            }
           
          });
      
       }


     
    },

    clearError(field) {

$("#" + field).removeClass("error-input");


},

    emailverification() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";
      
      if (this.otp == null) {
        this.otperror = "Enter OTP";
      } else if (this.otp.length < 6) {
        this.otperror = "Enter 6 digit OTP";
      } else {
        axios
          .post("/access/verification/", {
            email: this.email,
            verification_code: this.otp,
          })
          .then((res) => {
            if (res.status == 202) {
              alert("Verification Successfully");

              this.$router.push({ name: "login" });
            }
          })
          .catch((error) => {
            var loader = document.getElementById("loader-small");

            loader.style.display = "none";
           alert("Your otp expired or invalid")
            console.log(error.response);
          });
      }
    },
  },
};
</script>



