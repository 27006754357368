<template>
    <!--Page Title-->
    <div>
      <br /><br /><br />
      <section class="page-title style-two">
        <div class="auto-container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="sec-title text-center">
                <h2 class="text-white" style="font-size: 45px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">{{city_name}}</h2>
               
              </div>
            </div>
          </div>
  
          <div style="background-color: white;border-radius: 10px;" >
  
           <div class="row">
  
            <div class="col-lg-10" style="margin:0px;padding: 0px;z-index: 10;">
  
              <div  class="form-group text-left px-5 py-3 ">
                  <span style="color:grey" class="icon flaticon-search-1"></span>
                  <input style="border-radius: 10px;width: 80%;color: grey;padding-left: 20px;"
                    type="text"
                    @input="showjob($event.target.value)"
                    name="field_name"
                    placeholder=" title, keywords, or university"
  
                    
                  />
  
                 <span style="height: 100%;background-color: red;" ></span>
                </div>
  
            </div>
            <div  id="find-job" class="col-2 pt-3" style="background-color: #3f4a5a;margin:0px;padding: 0px;border-radius: 0px 10px 10px 0px;color:white" >
  
              <span >FIND UNIVERSITY</span>
              
            </div>
  
  
           </div>
          
  
  
  
            
  
  
  
          </div>
  
           
          
          
         
        </div>
      </section>
      <!--End Page Title-->
  
      <!-- Listing Section -->
  
  
          
      <section class="job-section-five style-two">
        <div class="auto-container">
          <div class="sec-title text-left">
  
          </div>
  
          <div style="z-index: 9" class="row wow fadeInUp ">
            <div @click.prevent="show_university(uli.id)" v-for="uli in ulist" :key="uli" class="col-md-6 p-3">
              <div class="custom-card p-3">
                <div class="row">
                  <div class="col-md-2 text-center m-auto">
                    <img
                    style="width: 70px; height: 70px; object-fit: cover; border-radius: 50%;"
                     :src="imageurl+uli.logo"
                      alt=""
                    />
                  </div>
  
                  <div class="col-md-7 mt-sm-2">
                    <h6
                      class="font110"
                      style="text-align: left; font-weight: 500"
                    >
                     {{ uli.name }}
                    </h6>
  
                    <div class="row mt-1">
                      <div class="col-8">
                        <span class="icon flaticon-briefcase"></span>
  
                        <span v-if=" uli.scholarship_open == 1"  class="small-font ms-2"> Scholarship Open</span>
                        <span v-else  class="small-font ms-2"> Scholarship Not Open</span>
                      </div>
  
                      <div class="col-4">
                        <span class="icon flaticon-map-locator"></span>
                        <span class="small-font ms-2"> {{ uli.city }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8">
                        <span class="icon flaticon-clock-3"></span>
  
                        <span class="small-font ms-2"> {{ uli.deadline }} (Deadline) </span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-3 text-lg-center mt-sm-3 m-lg-auto">
                    <button href="#" class="theme-button mt-2 p-2">
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
  
          
  
          
  
         
          </div>
        </div>
      </section>
     
      <!--End Listing Page Section -->
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Constant from "@/interceptor/Constant.js";
  
  export default {
    data() {
      return {
      
        ulist: [],
        imageurl : Constant.filebaseurl+"/image/university/",
        city_name : ""
  
        
      };
    },
  
    methods: {
      unlist(city) {
        axios
          .get("api/web/university/city/"+city)
          .then((res) => {
           
              this.ulist = res.data.data;
  
            
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
      show_university(id){
  
  if(id){
  
    this.$router.push({ name: "university", query: { id: id } });
  
  
  }
  
  
  },
  
   
  
  
    
    },
  
    mounted: function () {
      this.city_name = this.$route.query.name;
      this.unlist(this.city_name);

      
    },
  };
  </script>
  
  <style>
  </style>