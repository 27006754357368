<template>
    <!--Page Title-->
    <div>
        
      <br /><br /><br />
      <section class="page-title style-two">
        <div class="auto-container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="sec-title text-center">
                <h2 class="text-white" style="font-size: 45px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">{{ university_name }}</h2>
                <div class="text text-white" style="font-size: 18px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">
                  Choose your desire program !
                </div>
              </div>
            </div>
          </div>
  
          <div style="background-color: white;border-radius: 10px;" >
  
           <div class="row">
  
            <div class="col-lg-10" style="margin:0px;padding: 0px;z-index: 10;">
  
              <div  class="form-group text-left px-5 py-3 ">
                  <span style="color:grey" class="icon flaticon-search-1"></span>
                  <input style="border-radius: 10px;width: 80%;color: grey;padding-left: 20px;"
                    type="text"
                    @input="showjob($event.target.value)"
                    name="field_name"
                    placeholder=" title, keywords, or course"
  
                    
                  />
  
                 <span style="height: 100%;background-color: red;" ></span>
                </div>
  
            </div>
            <div  id="find-job" class="col-2 pt-3" style="background-color: #3f4a5a;margin:0px;padding: 0px;border-radius: 0px 10px 10px 0px;color:white" >
  
              <span >FIND PROGARM</span>
              
            </div>
  
  
           </div>
          
  
  
  
            
  
  
  
          </div>
  
           
          
          
         
        </div>
      </section>
      <!--End Page Title-->
  
      <!-- Listing Section -->
      <section class="section">
      <div class="auto-container">
        <div class="sec-title text-left">
          <h2>Choose your Course</h2>
        </div>

        <div class="row wow fadeInUp">
          <!-- Category Block -->
          <div
           
            v-for="course in courses"
            :key="course"
            class="category-block col-lg-4 h-100 col-md-6 col-sm-12"
           
          >
            <div  @click.prevent="show_university(course.id)" class="inner-box text-center">
              <div class="row">


               
                <div class="col-12">
                  <h4 class="mt-1">
       
                    <a  href="#">{{ course.title }}</a>
                  </h4>
                  <p style="font-size: 80% ;font-weight: bold;" >{{ course.program.name }} </p>
                 


                  <div style="display: flex;justify-content: space-between;margin-top: 20px;" >

                    <div>
                      
                      Yarly Fee 
                      <div>{{ course.fee }}</div>     

                    
                    
                    </div>     

                    <div>
                      
                      Duration 
                      <div>{{ course.duration }} year</div>     

                    
                    
                    </div>   


                    <div>  
                      
                      
                      <button class="btn btn-success">Apply Now</button>



                    </div>


                  

                  </div>


                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <!--End Listing Page Section -->
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import vcookie from "vue-cookie";


  export default {
    data() {
      return {
        
        courses: [],
        university_id : "",
      university_name :"",
        
      };
    },
  
    methods: {
      courseListByUniversity(id) {
        axios
          .get("api/web/course/university/"+id)
          .then((res) => {
           
              this.courses = res.data.data;
  
            
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
      openjob(uuid) {
        if (uuid != null) {
          this.$router.push({ name: "job-single", query: { uuid: uuid } });
        }
      },
  
   

      
      apply(id){

        if(vcookie.get("user") != null){

          if(JSON.parse(vcookie.get("user")).role == "student"){

        axios.post("api/web/apply",{
          user_id : JSON.parse(vcookie.get("user")).id,
          university_id : this.university_id,
        course_id : id,
        description : "description"


       }).then((res) => {

        if(res){
          this.$emit("get_message", "Applied to Course Successfully");

        }
       
       }).catch((error) => {
         console.log(error.response);
       });




            
          }else{

            var route_name_stu = this.$route.name

       

this.$router.push({ name: "login" , query: { route_name: route_name_stu, id: this.university_id } });



          }


      
          
        }else{

          var route_name = this.$route.name



       

this.$router.push({ name: "login" , query: { route_name: route_name, id: this.university_id } });


       

        }


  
     
      },
  
  
    
    },
  
    mounted: function () {

      var university_id = this.$route.query.id;
      this.university_id = university_id;
      this.university_name = this.$route.query.name;
      this.courseListByUniversity(university_id);
    },
  };
  </script>
  
  <style>
  </style>