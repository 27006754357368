<template>
  <section class="job-detail-section">
    <div style="margin-top: 50px" class="job-detail-outer">
      <div class="auto-container">
        <div class="row">
          <div class="content-column col-lg-8 col-md-12 col-sm-12">
            <div class="job-block-outer">
              <!-- Job Block -->
              <div class="job-block-seven">
                <div class="inner-box">
                  <div class="content">
                    <span v-if="university.logo" class="company-logo"
                      ><img :src="imageurl + university.logo" alt=""
                    /></span>
                    <h4 class="utitle">
                      {{ university.name }}
                    </h4>
                    <ul class="job-info">
                      <!-- <li><span class="icon flaticon-briefcase"></span> Segment</li> -->
                      <li>
                        <span class="icon flaticon-map-locator"></span>
                        {{ university.city }} , China
                      </li>

                      <li>
                        <i class="las la-graduation-cap"></i>
                        {{ university.founded_at }}
                      </li>
                    </ul>
                    <ul class="job-other-info">
                      <li class="privacy">{{ university.type }}</li>
                      <li class="required">Scholarship open</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="job-detail">
              <figure v-if="university.bannar" class="image">
                <img
                  class="w-100"
                  style="height: 40%"
                  :src="imageurl + university.bannar"
                />
              </figure>

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active tab-btn"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#introduction"
                    type="button"
                    role="tab"
                  >

                  Introduction
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link tab-btn"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#abouts"
                    type="button"
                    role="tab"
                  >
                    About
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link tab-btn"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#accommodation"
                    type="button"
                    role="tab"
                  >
                  Accommodation
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link tab-btn"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#fee_structure"
                    type="button"
                    role="tab"
                  >
                  Fee Structure
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link tab-btn"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#admission_process"
                    type="button"
                    role="tab"
                  >
                  Admission Process
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link tab-btn"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#scholarship"
                    type="button"
                    role="tab"
                  >
                  Scholarship
                  </button>
                </li>
              
               
                
                
              </ul>

              <div class="tab-content mt-3" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="introduction"
                  role="tabpanel"
                >
                  <h5>Intoduction</h5>

                  <div  class="mt-3">
                    <p v-html="university.introduction"></p>

                  </div>
                </div>
                <div
                  class="tab-pane fade show "
                  id="accommodation"
                  role="tabpanel"
                >
                  <h5>Accommodation</h5>

                  <div  class="mt-3">
                    <p v-html="university.description"></p>

                  </div>
                </div>
               
                <div
                  class="tab-pane fade show "
                  id="abouts"
                  role="tabpanel"
                >
                  <h5>About</h5>

                  <div  class="mt-3">
                    <p v-html="university.description"></p>

                  </div>
                </div>

                <div
                  class="tab-pane fade show "
                  id="fee_structure"
                  role="tabpanel"
                >
                  <h5>Fee Structure</h5>

                  <div  class="mt-3 p-4 border">

                    <div class="p-1"><strong>Bachelor Tution Fee :</strong>  {{university.bachelor_fee }} </div>
                    <div class="p-1"><strong>Masters Tution Fee :</strong>  {{university.bachelor_fee }} </div>
                    <div class="p-1"><strong>PhD Tution Fee :</strong>  {{university.bachelor_fee }} </div>

                  </div>

                  <div class="mt-3 p-4 border" >

                    <h5>Other Fee</h5>

                    <p class="mt-3" v-html="university.other_fee"></p>





                  </div>


                </div>
                <div
                  class="tab-pane fade show "
                  id="admission_process"
                  role="tabpanel"
                >
                  <h5>Admission Process</h5>

                  <div  class="mt-3">

                  </div>
                </div>
               
                <div
                  class="tab-pane fade show "
                  id="scholarship"
                  role="tabpanel"
                >
                  <h5>Scholar Ship</h5>

                  <div  class="mt-3">

                  </div>
                </div>
               

               
              </div>


             
    
            </div>
            <!-- Other Options -->
            <div class="other-options">
              <div class="social-share">
                <h5>Share</h5>
                <a href="#" class="facebook"
                  ><i class="fab fa-facebook-f"></i> Facebook</a
                >
                <a href="#" class="twitter"
                  ><i class="fab fa-twitter"></i> Twitter</a
                >
                <a href="#" class="google"
                  ><i class="fab fa-google"></i> Google+</a
                >
              </div>
            </div>

            <!-- <div class="map-outer">
              <div
                class="map-canvas"
                data-zoom="12"
                data-lat="-37.817085"
                data-lng="144.955631"
                data-type="roadmap"
                data-hue="#ffc400"
                data-title="Envato"
                data-icon-path="images/resource/map-marker.png"
                data-content="Melbourne VIC 3000, Australia<br><a href='mailto:info@youremail.com'>info@youremail.com</a>"
              ></div>
            </div> -->
          </div>

          <div class="sidebar-column col-lg-4 col-md-12 col-sm-12">
            <aside class="sidebar">
              <div class="btn-box">
                <router-link
                  :to="{
                    name: 'university-programs',
                    query: { id: university.id, name: university.name },
                  }"
                  class="theme-btn btn"
                  >SEE PROGRAM</router-link
                >
                <button class="bookmark-btn">
                  <i class="flaticon-bookmark"></i>
                </button>
              </div>

              <div class="sidebar-widget">
                <!-- Job Overview -->
                <h4 class="widget-title">Program Overview</h4>
                <div class="widget-content">
                  <ul class="job-overview">
                    <li>
                      <i class="icon icon-calendar"></i>
                      <h5>Total program</h5>
                      <span>{{ university.total_program }}</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>Total Section</h5>
                      <span>{{ university.total_section }}</span>
                    </li>
                  </ul>
                </div>

                <!-- Job Skills -->
              </div>

              <div class="sidebar-widget contact-widget">
                <h4 class="widget-title">Contact Us</h4>
                <div class="widget-content">
                  <!-- Comment Form -->
                  <div class="default-form">
                    <!--Comment Form-->
                    <form>
                      <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="text"
                            name="username"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            required
                          />
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            class="darma"
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <button
                            class="theme-btn btn-style-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Constant from "@/interceptor/Constant.js";

export default {
  data() {
    return {
      university: "",
      imageurl: Constant.filebaseurl + "image/university/",
    };
  },

  methods: {
    getUniversity(id) {
      axios
        .get("/api/web/university/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.university = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted() {
    var id = this.$route.query.id;

    if (id != null) {
      this.getUniversity(id);
      window.scrollTo(0, 0);
    }
  },
};
</script>

