<template>
  <div>
    <!-- <div id="popbg">f</div> -->

    <div id="homepopup" style="display: none">
      <i @click="pophide" class="fa-solid fa-xmark popicon"></i>
      <div
        style="height: 100%"
        id="carouselExampleSlidesOnly"
        class="carousel"
        data-ride="carousel"
      >
        <div style="height: 100%" class="carousel-inner">
          <div
            style="object-fit: cover"
            v-for="popupad in popupads"
            :key="popupad.id"
            class="carousel-item active bgp2"
            v-bind:class="condition ? 'class_if_is_true' : 'else_class'"
          >
            <img
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              :src="popupad.background"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="shortside" class="d-none">
      <div style="height: 50%">
        <div class="shortbtn1 text-center pt-2">
          <a href="" style="color: white">PART TIME JOBS</a>
        </div>
      </div>
      <div style="height: 50%">
        <div style="height: 50%">
          <div class="shortbtn2 text-center pt-2">
            <a href="/" style="color: black">DIRECT HIRING</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Banner Section-->
    <section class="banner-section">
      <!-- <div id="mySidenav" class="sidenav">
          <a href="/parttimejob" id="about">Part-time Job</a>
          <a href="#" id="blog">Direct Hire</a>
          <a href="#" id="projects">Summer Job</a>
          <a href="#" id="contact">Feature Job</a>
    </div> -->
      <div class="auto-container">
        <div class="row">
          <div class="content-column col-lg-7 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp" data-wow-delay="1000ms">
              <div class="title-box">
                <h3>Study In China With 100% Scholarships</h3>
                <h5 class="text-small-white">
                  Find offers, Scholarships & Universities
                </h5>
              </div>

              <!-- Job Search Form -->
              <div class="job-search-form">
                <form>
                  <div class="row">
                    <div class="form-group col-lg-9 col-md-12 col-sm-12">
                      <span class="icon flaticon-search-1"></span>
                      <input
                        type="text"
                        class="font110"
                        v-model="search_key"
                        placeholder=" course, program, or universities"
                      />
                    </div>
                    <!-- Form Group -->
                
                    <!-- Form Group -->
                    <div
                      class="form-group col-lg-3 col-md-12 col-sm-12 btn-box"
                    >
                      <button
                        @click.prevent="search(search_key)"
                        class="btn-style-one theme-color-bg2"
                      >
                        <span class="btn-title"> FIND </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Job Search Form -->

              <!-- Popular Search -->
              <div class="text-white font140">Popular Searches :</div>
              <div class="mt-3 mb-5" style="cursor: pointer">
                <button  @click.prevent="search('offer')" class="theme-button3 font120 text-white">
                  <span                        
                  class="icon flaticon-search-1"></span> offer
                </button>
                <button  @click.prevent="search('china')" class="theme-button3 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> china
                </button>
                <button @click.prevent="search('student')" class="theme-button3 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> Student
                </button>
                <button @click.prevent="search('university')" class="theme-button3 mt-2 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> University
                </button>

                <button @click.prevent="search('career')" class="theme-button3 mt-2 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> Career
                </button>
              </div>

              <!-- <div class="mt-4 mb-5">
                <button class="theme-button4 font120">
                  <a class="theme-color2" href="/parttimejob"> PART TIME JOB</a>
                </button>
                <button href="/directhire" class="theme-button4 mt-2 font120">
                  <a class="theme-color2" href="/directhire">DIRECT HIRE</a>
                </button>
                <button class="theme-button4 mt-2 font120">
                  <a class="theme-color2" @click.prevent="opentierjob"
                    >TIER 2 SPONSORSHIP</a
                  >
                </button>
              </div> -->
              <!-- End Popular Search -->
            </div>
          </div>

          <div class="image-column col-lg-5 col-md-12">
            <div class="image-box">
              <div class="main-image wow fadeIn" data-wow-delay="500ms">
                <img src="images/girl.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features__section">
      <div class="auto-container">
        <div class="row features__grid justify-content-between">
          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="bi bi-clock-history theme-color2"></i></div>
              </div>
              <div class="title">
                <a href="/" class="theme-color2">20K+ ADDMISSION COMPLETED </a>
              </div>
            </div>
          </div>

          <div class="col-lg-auto features-line-col">
            <div class="features-line"></div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="fa-solid fa-person theme-color2"></i></div>
              </div>

              <div class="title">
                <a href="/" class="theme-color2">100+ UNIVERSITIES</a>
              </div>
            </div>
          </div>

          <div class="col-lg-auto features-line-col">
            <div class="features-line"></div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="las la-person-booth theme-color2"></i></div>
              </div>
              <div class="title">
                <a @click.prevent="" class="theme-color2">SPONSORSHIP</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Banner Section-->
    <!-- Job Categories -->

    
    <section class="job-section-five style-two">
      <div class="auto-container">
        <div class="sec-title text-left">
          <h2 class="theme-color2">Discover China's World Class Universities | Chinese Universities</h2>

        </div>

        <div style="z-index: 9" class="row wow fadeInUp ">
          <div @click.prevent="show_university(topfive.id)" v-for="topfive in topfives" :key="topfive" class="col-md-6 p-3">
            <div class="custom-card p-3">
              <div class="row">
                <div class="col-md-2 text-center m-auto">
                  <img
                  style="width: 70px; height: 70px; object-fit: cover; border-radius: 50%;"
                   :src="imageurl+topfive.logo"
                    alt=""
                  />
                </div>

                <div class="col-md-7 mt-sm-2">
                  <h6
                    class="font110"
                    style="text-align: left; font-weight: 500"
                  >
                   {{ topfive.name }}
                  </h6>

                  <div class="row mt-1">
                    <div class="col-8">
                      <span class="icon flaticon-briefcase"></span>

                      <span v-if=" topfive.scholarship_open == 1"  class="small-font ms-2"> Scholarship Open</span>
                      <span v-else  class="small-font ms-2"> Scholarship Not Open</span>
                    </div>

                    <div class="col-4">
                      <span class="icon flaticon-map-locator"></span>
                      <span class="small-font ms-2"> {{ topfive.city }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <span class="icon flaticon-clock-3"></span>

                      <span class="small-font ms-2"> {{ topfive.deadline }} (Deadline) </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 text-lg-center mt-sm-3 m-lg-auto">
                  <button href="#" class="theme-button mt-2 p-2">
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>

        

        

       
        </div>
      </div>
    </section>
   

    <section class="section">
      <div class="auto-container">
        <div class="sec-title text-left">
          <h2>Choose your city</h2>
        </div>

        <div class="row wow fadeInUp">
          <!-- Category Block -->
          <div
           
            v-for="city in cities"
            :key="city"
            class="category-block col-lg-4 h-100 col-md-6 col-sm-12"
           
          >
            <div  @click.prevent="show_city_university(city.name)" class="inner-box text-left">
              <div class="row">
               
                <div class="col-12">
                  <h4 class="mt-1">
       
                    <a  href="#">{{ city.name }}</a>
                  </h4>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="auto-container">
        <div class="sec-title text-left">
          <h2>Choose your Degree</h2>
        </div>

        <div class="row wow fadeInUp">
          <!-- Category Block -->
          <div
           
            v-for="program in programs"
            :key="program"
            class="category-block col-lg-4 h-100 col-md-6 col-sm-12"
           
          >
            <div  @click.prevent="goto_programs(program.id)" class="inner-box text-left">
              <div class="row">
               
                <div class="col-12">
                  <h4 class="mt-1">
       
                    <a  href="#">{{ program.name }}</a>
                  </h4>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="section">
      <div class="auto-container">
        <div class="sec-title text-left">
          <h2>Choose your Course</h2>
        </div>

        <div class="row wow fadeInUp">
          <!-- Category Block -->
          <div
           
            v-for="course in courses"
            :key="course"
            class="category-block col-lg-4 h-100 col-md-6 col-sm-12"
           
          >
            <div  @click.prevent="show_course(course.id)" class="inner-box text-center">
              <div class="row">


                <img :src="imageurl+course.university.logo" style="width: 100px; height: 70px;margin: 0 auto;" alt="">
               
                <div class="col-12">
                  <h4 class="mt-1">
       
                    <a  href="#">{{ course.title }}</a>
                  </h4>
                  <p style="font-size: 80% ;font-weight: bold;" >{{ course.university.name }} </p>
                  <p style="font-size: 80% ;" >{{ course.university.city }} </p>


                  <div style="display: flex;justify-content: space-between;margin-top: 20px;" >

                    <div>
                      
                      Yarly Fee 
                      <div>{{ course.fee }}</div>     

                    
                    
                    </div>     

                    <div>
                      
                      Duration 
                      <div>{{ course.duration }} year</div>     

                    
                    
                    </div>   


                    <div>  
                      
                      
                      <button  class="btn btn-success">Check it </button>



                    </div>


                  

                  </div>


                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
  
    <!-- Commercial Ad Section -->

   



 

    

    <!-- End Testimonial Section -->

    <!-- steps Section -->
    <section class="steps-section pt-0">
      <div class="auto-container">
        <div class="row">
          <div class="image-column mt-4 col-lg-7 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp p-5">
              <figure class="">
                <img
                  height="100%"
                  width="110%"
                  src="images/resource/ledy.jpg"
                  alt=""
                />
              </figure>
              <!-- Count Employers -->
              <!--div class="count-employers wow fadeInUp">
                <span class="title">300k+ Employers</span>
                <figure class="image"><img src="images/resource/multi-peoples.png" alt=""></figure>
              </div-->
            </div>
          </div>

          <div class="content-column col-lg-5 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp">
              <div class="sec-title">
                <h2>STEPS OF APPLICATION</h2>
                <h5 class="text font110"></h5>
                <ul class="steps-list">
                  <li>
                    <span class="count">1</span> Need to prepare application
                    documents for submission
                  </li>
                  <li>
                    <span class="count">2</span>We will proceed for next step
                    with your application materials for review
                  </li>
                  <li>
                    <span class="count">3</span> You will get admission result
                    with a very short period of time after review
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Steps Section -->

    <!-- Job Section -->

    <section class="job-section-five style-two iam">
      <div class="auto-container">
        <div class="row wow fadeInUp">
          <div class="recent-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
            <div class="content-column">
              <div class="inner-column iamrec wow fadeInUp">
                <div class="sec-title">
                  <h1>ARE YOU A STUDENT ?</h1>
                  <h5 class="text font110 text-justify">
                    You will get a high services from us!
                  </h5>
                </div>

                <a
                  style="font-size: 110%; z-index: 30; margin-top: 20px"
                  href="/universitylist"
                  class="btn-style-one theme-color-bg"
                  >Apply Now</a
                >
              </div>
            </div>
          </div>

          <div class="featured-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
            <div class="image-column">
              <figure>
                <img src="images/student.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Job Section -->
    <!-- End Job Section -->

    <!-- Recruiter Section -->
    <!-- <section class="recruiter-section">
      <div class="outer-box">
        <div class="image-column">
          <figure><img src="images/resource/Recruiter.jpg" alt="" /></figure>
        </div>
        <div class="content-column">
          <div class="inner-column wow fadeInUp">
            <div class="sec-title">
              <h2>I am Recruiter</h2>
              <div class="text">
                One of our One of our jobs has some kind of flexibility jobs has
                some kind of flexibility option such as telecommuting, a
                part-time schedule or a flexible or flextime.
              </div>
              <a
                style="font-size: 130%; z-index: 30"
                href="/login"
                class="theme-btn btn-style-one"
                >Post New Job</a
              >
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Recruiter Section -->
    <!-- Top Companies -->
    <br />
  
    <!--End Page Title-->

    <!-- Testimonials Section -->
    
    <!-- End Testimonials Section -->

    <!-- Call To Action Three -->
    <section class="let-section call-to-action-three theme-color-bg4">
      <div class="auto-container">
        <div class="outer-box">
          <div class="sec-title">
            <h2>Let student find you</h2>
            <div class="text">
              Since pandaxue is a premium job board that continuously earns the
              top spot in our rankings for both applyer and student, we advise
              you to give it a try.
            </div>
          </div>

          <div class="btn-box">
            <a href="/job" class="theme-btn btn-style-one theme-color-bg"
              ><span class="btn-title">Search University</span></a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action -->
  </div>
</template>

<script>
import axios from "axios";
// import $ from "jquery";
import Constant from "@/interceptor/Constant.js";
import vcookie from "vue-cookie";


export default {
  data() {
    return {
      show: false,
      load: false,
      imageurl : Constant.filebaseurl+"/image/university/",


      images: [],

      hotjobs: [],
      search_key: "",
      adboards: [],
      universities: [],
      cities: [],
      programs: [],
      topfives:[],
      populars: [],
      courses : [],
      
    };
  },

  methods: {


 


 
   

    search() {
      if (this.search_key != "") {
        this.$router.push({ name: "programs", query: { key: this.search_key } });
      }

     
    },

  

  

  
  get_university() {
      axios
        .get("/api/admin/university")
        .then((res) => {
          if (res.status == 200) {
            this.universities = res.data.data;

            console.log(this.universities);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },


    getCity() {
      axios
        .get("/api/web/city")
        .then((res) => {
        
            this.cities = res.data.data;

          
        })
        .catch((error) => {
          console.log(error.response);
        });
    },


    getProgram() {
      axios
        .get("/api/web/degree")
        .then((res) => {
        
            this.programs = res.data.data;

          
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getCourse() {
      axios
        .get("/api/web/courses/for_home")
        .then((res) => {
        
            this.courses = res.data.data;

          
        })
        .catch((error) => {
          console.log(error.response);
        });
    },


    show_course(id){

      if(id){

        this.$router.push({ name: "course", query: { id: id } });

      }
    },

 

  


    show_city_university(name){

      if(name){

        this.$router.push({ name: "universityCitylist", query: { name: name } });


      }


    },

    show_university(id){

      if(id){

        this.$router.push({ name: "university", query: { id: id } });
      }


    },


    topu(){
      axios
        .get("api/web/university/topfive")
        .then((res) => {
         
            this.topfives = res.data.data;

          
        })
        .catch((error) => {
          console.log(error.response);
        });

    },

    
    popular(){
      axios
        .get("api/web/university/popular")
        .then((res) => {
         
            this.populars = res.data.data;

          
        })
        .catch((error) => {
          console.log(error.response);
        });

    },

    goto_programs(id){


      this.$router.push({ name: "programs", query: { program_id: id } });



    },


    apply(id,program_id,university_id){

if(vcookie.get("user") != null){

  if(JSON.parse(vcookie.get("user")).role == "student"){

axios.post("api/web/application",{
user_id : JSON.parse(vcookie.get("user")).id,
university_id : university_id,
course_id : id,
program_id : program_id,
description : "description"


}).then((res) => {

if(res){
  // this.$emit("get_message", "Applied to Course Successfully");
     
  this.$router.push({ name: "application" , query: {  application_id: res.data.data.id } });

}

}).catch((error) => {
 console.log(error.response);
});




    
  }else{

  var route_name_stu = this.$route.name



   this.$router.push({ name: "login" , query: { route_name: route_name_stu, id: this.university_id } });



  }



  
}else{

  var route_name = this.$route.name





this.$router.push({ name: "login" , query: { route_name: route_name, id: this.university_id } });




}




},


    
  },

  
  

  mounted: function () {
  
    this.topu();
    this.popular()
    this.getCity();
    this.getProgram();
    this.getCourse();
  },
};
</script>

<style>
#mySidenav {
  position: fixed;

  top: 250px;

  right: 0;
}

/* Style the links inside the sidenav */
#mySidenav a {
  position: absolute; /* Position them relative to the browser window */
  left: -20px; /* Position them outside of the screen */
  transition: 0.3s; /* Add transition on hover */
  padding: 15px 15px 15px 30px; /* 15px padding */
  width: 150px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  text-orientation: sideways-right;
  font-size: 20px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
  overflow: hidden; /* Hide scrollbars */
  overflow-y: hidden; /* Hide vertical scrollbar */
  /*overflow-x: hidden; /* Hide horizontal scrollbar */
  z-index: 14;
  opacity: 100% !important;
}

#mySidenav a:hover {
  left: 0; /* On mouse-over, make the elements appear as they should */
}

/* The about link: 20px from the top with a green background */
#about {
  top: 20px;

  background-color: #04aa6d;
}

#blog {
  top: 80px;
  background-color: #2196f3; /* Blue */
}

#projects {
  top: 140px;
  background-color: #f44336; /* Red */
}

#contact {
  top: 200px;
  background-color: #555; /* Light Black */
}
</style>
