<template>
  <div style="margin-top: 50px; padding: 50px">
    <div class="row">





      <div class="col-lg-8 p-4    ">

        <form action="" class="form">

          <h4 class="text-center" >Your Application</h4>

          <!-- Progress bar -->

          <div class="progressbar">
            <div class="progress" id="progress"></div>
            <div class="progress-step progress-step-active" data-title = "Contact " ></div>
            <div class="progress-step" data-title = "Family" ></div>
            <div class="progress-step" data-title = "Declaration" ></div>
            <div class="progress-step" data-title = "Documents" ></div>
          </div>

          <!-- Step -->
          <div class="form-step form-step-active">

            <h5 class="mt-5">Contact Details</h5>

            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                    id ="email"
                    class="form-control"
                    type="text"
                    v-model="email"
                    @input="clearError('email')"
                    name=""
                    placeholder="Email"
                />
              </div>

              <div class="col-lg-6">
                <input
                    id ="phone"
                    class="form-control"
                    v-model="phone"
                    type="text"
                    name=""
                    placeholder="Phone"
                />
              </div>
              <div class="col-lg-12 mt-2">
                <input
                    class="form-control"
                    v-model="wechat"
                    type="text"
                    name=""
                    @input="clearError('wechat')"
                    placeholder="Wechat/whatsapp"
                    id="wechat"
                />
              </div>
            </div>

            <h5 class="mt-3">Student's Information</h5>

            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                    class="form-control"
                    v-model="first_name"
                    type="text"
                    name=""
                    placeholder="First Name"
                    id=""
                />
              </div>

              <div class="col-lg-6">
                <input
                    class="form-control"
                    v-model="middle_name"
                    type="text"
                    name=""
                    placeholder="Middle Name"
                    id=""
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="last_name"
                    type="text"
                    name=""
                    placeholder="Last Name"
                    id=""
                />
              </div>


              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="chinese_name"
                    type="text"
                    name=""
                    placeholder="Chinese Name"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="date"
                    v-model="date_of_birth"
                    name=""
                    placeholder="Date of Birth"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="place_of_birth"
                    type="text"
                    name=""
                    placeholder="Place of Birth"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="passport_number"
                    type="text"
                    name=""
                    placeholder="Passport number"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="passport_expiry_date"
                    name=""
                    placeholder="Passport Expiry Date"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <select v-model="nationality" class="form-select" aria-label="Default select example">
                  <option disabled value="null">Nationality</option>
                  <option value="China">China</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Pakistan">Pakistan</option>
                </select>
              </div>
              <div class="col-lg-6 mt-2">
                <select v-model="religion" class="form-select" aria-label="Default select example">
                  <option disabled value="null"> Select Religion</option>
                  <option value="Islam">Islam</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Christian">Christian</option>
                  <option value="Buddhist">Buddhist</option>
                </select>
              </div>

              <div class="col-lg-6 mt-2">
                <select v-model="gender" class="form-select" aria-label="Default select example">
                  <option disabled value="null">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div class="col-lg-6 mt-2">
                <select v-model="marital_status" class="form-select" aria-label="Default select example">
                  <option disabled value="null">Marital status</option>
                  <option value="Single">Single</option>
                  <option value="Marrid">Married</option>
                </select>
              </div>
              <div class="col-lg-6 mt-2">
                <select v-model="check_china" class="form-select" aria-label="Default select example">
                  <option disabled  value="null" >Are you currently in china</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div class="col-lg-6 mt-2">
                <select v-model="check_alcohol"  class="form-select" aria-label="Default select example">
                  <option disabled value="null" >Are you addicted in alcohol</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div class="col-lg-12 mt-2">
                <textarea v-model="hobbies" class="form-control" name="" placeholder="Hobbies and Interests" id="" cols="30" rows="5"></textarea>

              </div>
            </div>

            <h5 class="mt-3">Language Proficiency</h5>


            <div class="row mt-4">












              <div class="col-lg-12 mt-2">
                <select v-model="native_language" class="form-select" aria-label="Default select example">
                  <option value="null" disabled >Select a language</option>
                  <option value="Arabic">Arabic</option>
                  <option value="Bengali">Bengali</option>
                  <option value="Chinese">Chinese</option>
                  <option value="English">English</option>
                  
                </select>
              </div>




              <div class="col-lg-12 mt-2">
                <select v-model="english_level" class="form-select" aria-label="Default select example">
                  <option disabled value="null">Select English Lavel</option>
                  <option value="1-poor">1-poor</option>
                  <option value="2-Fair">2-Fair</option>
                  <option value="3-Good<">3-Good</option>
                  <option value="4-Excellent">4-Excellent</option>
                </select>
              </div>
              <div class="col-lg-12 mt-2">
                <select v-model="english_certificate" class="form-select" aria-label="Default select example">
                  <option disabled value="null">Certificate of English Proficiency</option>
                  <option value="1">IELTS</option>
                  <option value="2">Tofel</option>
                  <option value="3">Doulingo</option>
                  <option value="3">Other</option>
                </select>
              </div>

              <div class="col-lg-12 mt-2">
                <select  v-model="chinese_level" class="form-select" aria-label="Default select example">
                  <option disabled value="null">Select Chinese Level</option>
                  <option value="1">Fair</option>
                  <option value="2">Good</option>
                  <option value="3">Excellent</option>
                </select>
              </div>

            </div>
            <h5 class="mt-3">Home Address Details</h5>

            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                    class="form-control"
                    v-model="country"
                    type="text"
                    name=""
                    placeholder="Country"
                    id=""
                />
              </div>

              <div class="col-lg-6">
                <input
                    class="form-control"
                    type="text"
                    v-model="city"
                    name=""
                    placeholder="City"
                    id=""
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="district"
                    name=""
                    placeholder="District"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="street"
                    name=""
                    placeholder="Street"
                    id=""
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="postal_code"
                    name=""
                    placeholder="Postal Code"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="receiver_name"
                    name=""
                    placeholder="Receiver name"
                    id=""
                />
              </div>










            </div>
            <h5 class="mt-3">Postal Address Details</h5>

            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                    class="form-control"
                    type="text"
                    v-model = "country"
                    name=""
                    placeholder="Country"
                    id=""
                />
              </div>

              <div class="col-lg-6" >
                <input
                    class="form-control "
                    type="text"
                    v-model = "city"
                    placeholder="City"
                    id=""
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model = "district"
                    name=""
                    placeholder="District"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model = "street"
                    name=""
                    placeholder="Street"
                    id=""
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model = "postal_code"
                    name=""
                    placeholder="Postal Code"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model = "receiver_name"
                    name=""
                    placeholder="Receiver name"
                    id=""
                />
              </div>










            </div>
            <h5 class="mt-3">Education Background</h5>

            <button @click.prevent="add_education" class="btn btn-success mt-2">Add Education </button>

            <div v-for="(education, index) in educations"  :key="index" class="education-container" >

              <div  class="row  mt-4">
                <div class="col-lg-6">
                  <input
                      class="form-control"
                      type="text"
                      v-model="education.school"
                      name=""
                      placeholder="School"
                      id=""
                  />
                </div>

                <div class="col-lg-6">
                  <input
                      class="form-control"
                      type="text"
                      v-model="education.mejor"
                      name=""
                      placeholder="Mejor"
                      id=""
                  />
                </div>

                <div class="col-lg-6 mt-2">
                  <input
                      class="form-control"
                      type="text"
                      v-model="education.started"
                      name=""
                      placeholder="Started"
                      id=""
                  />
                </div>
                <div class="col-lg-6 mt-2">
                  <input
                      class="form-control"
                      type="text"
                      v-model="education.finished"
                      name=""
                      placeholder="finished"
                      id=""
                  />
                </div>

                <div class="col-lg-6 mt-2">
                  <input
                      class="form-control"
                      type="text"
                      v-model="education.gpa"
                      name=""
                      placeholder="GPA"
                      id=""
                  />
                </div>
                <div class="col-lg-6 mt-2">
                  <input
                      class="form-control"
                      type="text"
                      v-model="education.country"
                      name=""
                      placeholder="Country"
                      id=""
                  />
                </div>









              </div>
              <hr>

              <button v-if="educations.length>1" @click.prevent="remove_education(index)" class="btn btn-danger mt-2">Remove</button>



            </div>

            <h5 class="mt-3">Work Exprience</h5>

            <button @click.prevent="add_work" class="btn btn-success mt-2">Add Work Exprience </button>
     <div class="work-container" v-for="(work,index) in works" :key="index" >
       <div class="row mt-4">
         <div class="col-lg-6">
           <input
               class="form-control"
               type="text"
               v-model="work.employer"
               name=""
               placeholder="Employer"
               id=""
           />
         </div>

         <div class="col-lg-6">
           <input
               class="form-control"
               type="text"
               v-model="work.job_title"
               name=""
               placeholder="Job Title"
               id=""
           />
         </div>

         <div class="col-lg-6 mt-2">
           <input
               class="form-control"
               type="text"
               v-model="work.started"
               name=""
               placeholder="Started"
               id=""
           />
         </div>
         <div class="col-lg-6 mt-2">
           <input
               class="form-control  "
               type="text"
               v-model="work.finished"
               name=""
               placeholder="Finished"
               id=""
           />
         </div>














       </div>

       <hr>


       <button v-if="works.length>1" @click.prevent="remove_work(index)" class="btn btn-danger mt-2">Remove</button>




     </div>







            <div>

              <a href="#" @click.prevent="step_name='contact'" class="btn btn-next width-50 ml-auto mt-2">Next</a>


            </div>
          </div>
          <div class="form-step ">
            <button class="btn btn-success float-end mt-2">Add Family Member </button>
            <h5 class="mt-5">Family Contact</h5>
            <p>Family Member</p>

            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                    class="form-control"
                    type="text"
                    v-model = "member_name"
                    placeholder="Name"
                    id="member_name"
                />
              </div>
              <div class="col-lg-6">
                <input
                    class="form-control"
                    type="text"
                    v-model="member_nationality"
                    name=""
                    placeholder="Nationality"
                    id="member_nationality"
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="member_phone"
                    placeholder="Phone"
                    id="member_phone"
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="member_email"
                    name=""
                    placeholder="Email"
                    id=""
                />
              </div>


              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="member_work_place"
                    name=""
                    placeholder="Work Place"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="member_relationship"
                    name=""
                    placeholder="Relationship"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="member_position"
                    name=""
                    placeholder="Position"
                    id=""
                />
              </div>
            </div>
            <h5 class="mt-3">Financial Supporter</h5>

            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                    class="form-control"
                    type="text"
                    v-model="supporters_relation"
                    name=""
                    placeholder="Relationship"
                    id=""
                />
              </div>

              <div class="col-lg-6">
                <input
                    class="form-control"
                    v-model="supporters_name"
                    type="text"
                    name=""
                    placeholder="Supporter's name"
                    id="supporter_name"
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="supporters_address"
                    type="text"
                    name=""
                    placeholder="Supporter's address"
                    id="supporter_address"
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="supporters_phone"
                    type="text"
                    name=""
                    placeholder="Phone"
                    id=""
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="supporters_email"
                    type="text"
                    name=""
                    placeholder=" email"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="supporters_workplace"
                    type="text"
                    name=""
                    placeholder="Workplace"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    type="text"
                    v-model="supporters_workplace_address"
                    name=""
                    placeholder="Workplace Address "
                    id=""
                />
              </div>









            </div>




            <h5 class="mt-3">Contact in Case of Emergencies</h5>


            <div class="row mt-4">
              <div class="col-lg-6">
                <input
                    class="form-control"
                    type="text"
                    v-model="emergencies_name"
                    name=""
                    placeholder="Name"
                    id=""
                />
              </div>

              <div class="col-lg-6">
                <input
                    class="form-control"
                    type="text"
                    v-model="emergencies_phone"
                    name=""
                    placeholder="Phone"
                    id=""
                />
              </div>

              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model="emergencies_email"
                    type="text"
                    name=""
                    placeholder="Email"
                    id=""
                />
              </div>
              <div class="col-lg-6 mt-2">
                <input
                    class="form-control"
                    v-model=" emergencies_address"
                    type="text"
                    name=""
                    placeholder="Address"
                    id=""
                />
              </div>














            </div>


            <div class="btns-group mt-3" >

              <a href="#" class="btn btn-prev">Previous</a>
              <a href="#" @click.prevent="step_name='family'" class="btn btn-next">Next</a>


            </div>
          </div>
          <div class="form-step ">
            <div class="row mt-4">


              <h6 style="font-weight: bold ;margin-top: 10px;">1. Acknowledgment</h6>
              <span style="display: inline;">   <input v-model = "acknowledgement" type="checkbox"   />
    I have read and understood the Terms and Conditions and Privacy Policy of MalishaEdu.</span>

              <h6 style="font-weight: bold;margin-top: 20px;">2. Consent</h6>
              <span style="display: inline;">   <input type="checkbox" v-model ="consent" value="Bike" />
    I give consent to MalishaEdu to collect, process, and share my data with relevant parties for the purpose of admission and visa processing.</span>


              <h6 style="font-weight: bold;margin-top: 20px;">3. Accuracy of Information</h6>
              <span style="display: inline;">   <input type="checkbox"  v-model ="information"  name="vehicle1" value="Bike" />
    I declare that the information and documents provided in my application are accurate and authentic to the best of my knowledge.</span>


              <h6 style="font-weight: bold;margin-top: 20px;"> 4. Payment Policy</h6>
              <span style="display: inline;">   <input type="checkbox" v-model ="payment" name="vehicle1" value="Bike" />
    I have read and understood the Payment policy of MalishaEdu and agree to its terms.</span>


              <h6 style="font-weight: bold;margin-top: 20px;">5. Refund Policy</h6>
              <span style="display: inline;">   <input type="checkbox" v-model = "refund" name="vehicle1" value="Bike" />
    I have read and understood the Refund Policy of MalishaEdu and agree to its terms</span>




              <h6 style="font-weight: bold;margin-top: 20px;">6. Compliance</h6>
              <span style="display: inline;">   <input type="checkbox" v-model = "compliance" name="vehicle1" value="Bike" />
    I agree to attend all required courses, interviews, and exams as outlined in the application process.</span>










            </div>


            <div class="btns-group mt-3" >

              <a href="#" class="btn btn-prev">Previous</a>
              <a href="#" @click.prevent="step_name='declaration'" class="btn btn-next">Next</a>


            </div>
          </div>
          <div class="form-step ">
            <div class="row mt-4">
              <div class="form-group">

                <label for="formFile" class="form-label">Upload Passport</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>

              <div class="form-group">

                <label for="formFile" class="form-label">Highest Education Certificate</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>

              <div class="form-group">

                <label for="formFile" class="form-label">Highest Education Transcript</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>

              <div class="form-group">

                <label for="formFile" class="form-label">Forigen Certificate</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>

              <div class="form-group">

                <label for="formFile" class="form-label">Non Criminal Certificate</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>

              <div class="form-group">

                <label for="formFile" class="form-label">Bank Solvency Certificate</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>

              <div class="form-group">

                <label for="formFile" class="form-label">Resume/CV</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>

              <div class="form-group">

                <label for="formFile" class="form-label">Adward Certificate</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>

              <div class="form-group">

                <label for="formFile" class="form-label">Mother's/Father's Certificate</label>
                <input class="form-control" @change="select_file" type="file" id="formFile">



              </div>





            </div>
           


            <div class="btns-group mt-2" >

              <a href="#" class="btn btn-prev">Previous</a>
              <a href="#" @click.prevent="step_name='documents'" class="btn btn-next ">Submit</a>


            </div>
          </div>
        </form>







       

</div>

 <div class="col-lg-4 p-4 ">


        <h4 class="text-center">Appication Summary</h4>
        <HR></HR>
        <div class="d-flex mt-3 " > Application ID : #121212  </div>
        <div class="d-flex mt-3 " > Application Fee : 500  </div>

        <div class="d-flex mt-3 " > Service Fee : 7500  </div>


      </div>

     
    </div>

   

  
  </div>
</template>
    
    <script>
import axios from "axios";
import { Validator } from "@/other/Validator";
import $ from "jquery";


export default {
  data() {
    return {

      // Conatct Details
      
      nationality : null,
      religion : null,
      gender : null,
      marital_status : null,
      check_china : null,
      check_alcohol : null,

      
      files : [],
      documents : [],


    

      // Language_proficiency
      
      native_language : null,
      english_level :null,
      english_certificate :null,
      chinese_level :null,
      application_id :null,

      


      educations :[
        {
        school : "",
        mejor : "",
        started : "",
        finished : "",
        gpa : "",
        country : "",

      }],

      works :[
        {
        employer : "",
        job_title : "",
        started : "",
        finished : "",
      

      }],
    }
  
  },



  methods: {

    clearError(field) {

      $("#" + field).removeClass("error-input");

      
    },
    check_contact(){

      var validator = new Validator();
      var error = validator.validated([
        { field: "email", value: this.email, type: "required" }, 

        {
          field: "phone",
          value: this.phone,
          type: "required",
        },

        {
          field: "wechat",
          value: this.wechat,
          type: "required",
        },


      ]);
      if (error) {
        return false;
       }else{
        return true;
       }



},
  
    add_education(){
       this.educations.push({
           

        school : "",
        mejor : "",
        started : "",
        finished : "",
        gpa : "",
        country : "",


       })

    },

    select_file(event) {
      var file = event.target.files[0];
      this.files.push(file);
      this.documents.push(file.name);
     },

    add_work(){
      this.works.push({
        employer : "",
        job_title : "",
        started : "",
        finished : "",
      
      })
           

      
      
    },
    remove_education(index){
      this.educations.splice(index, 1);
    },
    remove_work(index){
      this.works.splice(index, 1);
    },

   

    async save_contact(){


      var validator = new Validator();
      var error = validator.validated([
        { field: "email", value: this.email, type: "required" },

        {
          field: "phone",
          value: this.phone,
          type: "required",
        },

        {
          field: "wechat",
          value: this.wechat,
          type: "required",
        },


      ]);
      if (error) {
        return false;
      }else{

        var  contact_details = {
          email : this.email,
          phone : this.phone,
          wechat : this.wechat
        }


        var  students_information = {
          first_name : this.first_name,
          middle_name : this.middle_name,
          last_name : this.last_name,
          date_of_birth : this.date_of_birth,
          place_of_birth : this.place_of_birth,
          passport_number : this.passport_number,
          passport_expiry_date : this.passport_expiry_date,
          nationality : this.nationality,
          religion : this.religion,
          gender : this.gender,
          marital_status : this.marital_status,
          check_china : this.check_china,
          check_alcohol : this.check_alcohol,
        }
        var language_proficiency = {
          native_language : this.native_language,
          english_level : this.english_level,
          english_certificate : this.english_certificate,
          chinese_level : this.chinese_level
        }

        var home_address = {
          country : this.country,
          city : this.city,
          district : this.district,
          street : this.street,
          postal_code : this.postal_code,

          receiver_name : this.receiver_name,


        }

        var postal_address = {
          country : this.country,
          city : this.city,
          district : this.district,
          street : this.street,
          postal_code : this.postal_code,
          receiver_name : this.receiver_name,
        }


        var data = {
          conatct_details : JSON.stringify(contact_details),
          students_information : JSON.stringify(students_information),
          postal_address : JSON.stringify(postal_address),
          home_address : JSON.stringify(home_address),
          language_proficiency : JSON.stringify(language_proficiency),
          education_background : JSON.stringify(this.educations),
          work_experience : JSON.stringify(this.works),

        }

        try {
          const response = await axios.put("api/web/application/"+this.application_id,data);
          if(response){
            this.$emit("get_message", "Applied to Course Successfully");

          }

          return true; // Return true on success
        } catch (error) {
          console.error("Error fetching data:", error);
          return false; // Return false on error
        }


      }


    },
    async save_document(){


     
      var formData = new FormData();
      formData.append("_method", "PUT")

      for(let file of this.files){
        formData.append("files[]", file);
        formData.append("documents", JSON.stringify(this.documents));

      }
    
        try {
          const response = await axios.post("api/web/application/"+this.application_id,formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }

          );
          if(response){
            this.$emit("get_message", "Applied to Course Successfully");

          }

          return true; // Return true on success
        } catch (error) {
          console.error("Error fetching data:", error);
          return false; // Return false on error
        }


      


    },


    async save_family(){


var validator = new Validator();
var error = validator.validated([
  { field: "member_name", value: this.member_name, type: "required" },

  {
    field: "member_nationality",
    value: this.member_nationality,
    type: "required",
  },

  {
    field: "member_phone",
    value: this.member_phone,
    type: "required",
  },


]);
if (error) {
  return false;
 }else{

  var  family_conatct = {
    member_name : this.member_name,
    member_nationality : this.member_nationality,
    member_phone : this.member_phone,
    member_email : this.member_email,
    member_work_place : this.member_work_place,
    member_relationship : this.member_relationship,
    member_position : this.member_position,
  }

  var fiancial_supporter = {
    supporters_relation : this.supporters_relation,
    supporters_name : this.supporters_name,
    supporters_address : this.supporters_address,
    supporters_phone : this.supporters_phone,
    supporters_email : this.supporters_email,
    supporters_workplace : this.supporters_workplace,
    supporters_workplace_address : this.supporters_workplace_address,
  }

  var  conatact_case_emergency = {
    emergencies_name : this.emergencies_name,
    emergencies_phone : this.emergencies_phone,
    emergencies_email : this.emergencies_email,
    emergencies_address : this.emergencies_address,


  }


 


  var data = {
    family_conatct : JSON.stringify(family_conatct),
    fiancial_supporter : JSON.stringify(fiancial_supporter),
    conatact_case_emergency : JSON.stringify(conatact_case_emergency),


  }

  try {
  const response = await axios.put("api/web/application/"+this.application_id,data);
  if(response){
    this.$emit("get_message", "Applied to Course Successfully");

  }

  return true; // Return true on success
} catch (error) {
  console.error("Error fetching data:", error);
  return false; // Return false on error
}


 }


},
   
  async  save_declaration(){
     
     var declaration ={

      acknowledgement : this.acknowledgement,
      consent : this.consent,
      information : this.information,
      payment : this.payment,
      compliance : this.compliance,

     }

     var data ={

      declaration : JSON.stringify(declaration),
     }


     try {
  const response = await axios.put("api/web/application/"+this.application_id,data);
  if(response){
    this.$emit("get_message", "Applied to Course Successfully");

  }

  return true; // Return true on success
} catch (error) {
  console.error("Error fetching data:", error);
  return false; // Return false on error
}

  },



       step(){
      const  prevBtns = document.querySelectorAll(".btn-prev")

	const nextBtns = document.querySelectorAll(".btn-next")

	const progress = document.getElementById("progress");

	const formSteps = document.querySelectorAll(".form-step");

	const progressSteps = document.querySelectorAll(".progress-step")

	let formStepsNum = 0;
	nextBtns.forEach(btn =>{

		btn.addEventListener("click",  async () =>{

       if(this.step_name=="contact"){
        

        if( await this.save_contact()){
             
          
			formStepsNum++;
			updateFormSteps();
			updateProgressbar()

        }
         
       }  


       if(this.step_name=="family"){
        

        if( await this.save_family()){
             
          
			formStepsNum++;
			updateFormSteps();
			updateProgressbar()

        }
         
       }  

       if(this.step_name=="declaration"){
        

        if( await this.save_declaration()){
             
          
			formStepsNum++;
			updateFormSteps();
			updateProgressbar()

        }
         
       }  
       if(this.step_name=="documents"){
        

         await this.save_document()
             

        
         
       }  

      


      
		

		})


	})

	prevBtns.forEach(btn =>{

		btn.addEventListener("click",() =>{

			formStepsNum--;
			updateFormSteps();
			updateProgressbar()


		})


	})

	function updateFormSteps(){
		formSteps.forEach(formStep =>{
			formStep.classList.contains("form-step-active") &&
			formStep.classList.remove("form-step-active")

		})

		formSteps[formStepsNum].classList.add("form-step-active");
	}

	function updateProgressbar(){

		progressSteps.forEach((progressStep,idx) =>{
			if( idx < formStepsNum + 1){

				progressStep.classList.add("progress-step-active")


			}else{

				progressStep.classList.remove("progress-step-active")


			}


		})

		const progressActive = document.querySelectorAll(".progress-step-active");

		progress.style.width = ((progressActive.length-1)/(progressSteps.length-1 )) * 100 + "%";

	}
    }

    

   
    
  },

  mounted: function () {
    this.step()
    this.application_id = this.$route.query.application_id
  },
};
</script>
    
    <style>
</style>