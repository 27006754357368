<template>
  
  <div class="page-wrapper">
  

    <div v-if="msg" id="message_popup" class="alert alert-success">
      <div class="text-center">
        <!-- <img style="color: aqua;" src="https://www.svgrepo.com/show/506432/check-circle.svg" alt="AdminLTELogo" height="60" width="60"> -->
<!-- 
        <span style="font-size: 500%" class="material-symbols-outlined sc">
          task_alt
        </span> -->

        <!-- <i style="display: block;color:rgb(240, 144, 19);font-size:200%" class="fa-solid fa-check"></i> -->
        <h5 class="mt-3 pc">{{ msg }}</h5>
      </div>
    </div>


<!-- Preloader -->
<!-- <div class="preloader"></div -->


  <!-- <div class="loader-large" >


    
  <img style="height: 80px;width: 80px;" src="../../../public/images/resource/favicon.png" >
    <span class="loading__dot_large"></span>
    <span class="loading__dot_large"></span>
    <span class="loading__dot_large"></span>

  </div> -->

<!-- Main Header-->
<header-view></header-view>

<router-view  @get_message="message_show" ></router-view>
<!--End Main Header -->


<!-- Main Footer -->
<footer-view></footer-view>
<!-- End Main Footer -->

</div><!-- End Page Wrapper -->


  
</template>

<script>

import HeaderView from '@/components/frontend/HeaderView.vue';
import FooterView from '@/components/frontend/FooterView.vue';
export default{
    name:'MasterView',
    components:{HeaderView,FooterView},
      data(){
        return{
          msg: "",
      delete_msg: "",
      is_delete: false,
      dataToPass: false,

        }

      },
      methods:{

        message_show(message) {
      this.msg = message;

      this.message_hide();
    },
    delete_show(message) {
      this.delete_msg = message;

      this.delete_popup_show();

      if (document.getElementById("delete_popup")) {
        const box = document.getElementById("delete_popup");
        box.style.display = "block";
      }
    },
    delete_popup_show() {},

    message_hide() {
      setTimeout(() => {
        document.getElementById("message_popup").style.display = "none";
      }, 5000);

      if (document.getElementById("message_popup")) {
        const box = document.getElementById("message_popup");
        box.style.display = "block";
      }
    },

    

      },


     created() {
    var scripts = [


      "/frontend/js/script.js",
      "/frontend/js/multistep.js",

 
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.body.appendChild(tag);
    });
  }
};
</script>

<style>

</style>