<template>
    <section class="job-detail-section">
      <div style="margin-top: 50px" class="job-detail-outer">
        <div class="auto-container">
          <div class="row">
            <div class="content-column col-lg-8 col-md-12 col-sm-12">
              <div class="job-block-outer">
                <!-- Job Block -->
                <div class="job-block-seven">
                  <div class="inner-box">
                    <div class="content">
                      <span v-if="university.logo" class="company-logo"
                        ><img :src="imageurl + university.logo" alt=""
                      /></span>
                      <h4 class="utitle">
                        {{ course.title }}
                      </h4>
                      <ul class="job-info">
                        <!-- <li><span class="icon flaticon-briefcase"></span> Segment</li> -->
                        <li>
                          <span class="icon flaticon-map-locator"></span>
                          {{ university.name }} , {{ university.city }},China
                        </li>
  
                        <li>
                          <!-- <i class="las la-graduation-cap"></i> -->
                          {{ course.founded_at }}
                        </li>
                      </ul>
                      <ul class="job-other-info">
                        <li class="required">{{ program.name }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="job-detail">
                <figure v-if="university.bannar" class="image">
                  <img
                    class="w-100"
                    style="height: 40%"
                    :src="imageurl + university.bannar"
                  />
                </figure>
  
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active tab-btn"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#introduction"
                      type="button"
                      role="tab"
                    >
  
                    Overview
                    </button>
                  </li>
                 
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link tab-btn"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#accommodation"
                      type="button"
                      role="tab"
                    >
                    Accommodation
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link tab-btn"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#fee_structure"
                      type="button"
                      role="tab"
                    >
                    Fee Structure
                    </button>
                  </li>
              
               
                  
                </ul>
  
                <div class="tab-content mt-3" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="introduction"
                    role="tabpanel"
                  >
                    <h5>Overview</h5>
  
                    <div  class="mt-3">
                      <p v-html="course.course_description"></p>
  
                    </div>
                  </div>
                  <div
                    class="tab-pane fade show "
                    id="accommodation"
                    role="tabpanel"
                  >
                    <h5>Accommodation</h5>
  
                    <div  class="mt-3">
                      <p v-html="university.accommodation"></p>
  
                    </div>
                  </div>
                 
                  <div
                    class="tab-pane fade show "
                    id="abouts"
                    role="tabpanel"
                  >
                    <h5>About</h5>
  
                    <div  class="mt-3">
                      <p v-html="course.description"></p>
  
                    </div>
                  </div>
  
                  <div
                    class="tab-pane fade show "
                    id="fee_structure"
                    role="tabpanel"
                  >
                    <h5>Fee Structure</h5>
  
                    <div  class="mt-3 p-4 border">
  
                      <div class="p-1"><strong>Tution Fee :</strong>  {{course.fee }} </div>
                   
                    </div>
  
                    <div  class="mt-3 p-4 border">
  
                      <div class="p-1"><strong>Accommodation Fee :</strong>  {{university.accommodation_fee }} </div>
                   
                    </div>
  
                    <div class="mt-3 p-4 border" >
  
                      <h5>Other Fee</h5>
  
                      <p class="mt-3" v-html="university.other_fee"></p>
  
  
  
  
  
                    </div>
  
  
                  </div>
                  <div
                    class="tab-pane fade show "
                    id="admission_process"
                    role="tabpanel"
                  >
                    <h5>Admission Process</h5>
  
                    <div  class="mt-3">
  
                    </div>
                  </div>
                 
                  <div
                    class="tab-pane fade show "
                    id="scholarship"
                    role="tabpanel"
                  >
                    <h5>Scholar Ship</h5>
  
                    <div  class="mt-3">
  
                    </div>
                  </div>
                 
  
                 
                </div>
  
  
               
      
              </div>
              <!-- Other Options -->
              <div class="other-options">
                <div class="social-share">
                  <h5>Share</h5>
                  <a href="#" class="facebook"
                    ><i class="fab fa-facebook-f"></i> Facebook</a
                  >
                  <a href="#" class="twitter"
                    ><i class="fab fa-twitter"></i> Twitter</a
                  >
                  <a href="#" class="google"
                    ><i class="fab fa-google"></i> Google+</a
                  >
                </div>
              </div>
  
              <!-- <div class="map-outer">
                <div
                  class="map-canvas"
                  data-zoom="12"
                  data-lat="-37.817085"
                  data-lng="144.955631"
                  data-type="roadmap"
                  data-hue="#ffc400"
                  data-title="Envato"
                  data-icon-path="images/resource/map-marker.png"
                  data-content="Melbourne VIC 3000, Australia<br><a href='mailto:info@youremail.com'>info@youremail.com</a>"
                ></div>
              </div> -->
            </div>
  
            <div class="sidebar-column col-lg-4 col-md-12 col-sm-12">
              <aside class="sidebar">
                <div class="btn-box">
                  <button
                    @click.prevent="apply(course.id,course.program_id,course.university_id)"
                    class="theme-btn btn"
                    >Apply</button
                  >
                  <button class="bookmark-btn">
                    <i class="flaticon-bookmark"></i>
                  </button>
                </div>
  
                <div class="sidebar-widget">
                  <!-- Job Overview -->
                  <h4 class="widget-title">Program Overview</h4>
                  <div class="widget-content">
                    <ul class="job-overview">
                      <li>
                        <i class="icon icon-calendar"></i>
                        <h5>Course Duration</h5>
                        <span>{{ course.duration }} year </span>
                      </li>
                      <li>
                        <i class="icon icon-expiry"></i>
                        <h5>Mejor</h5>
                        <span>{{ course.mejor }}</span>
                      </li>
                    </ul>
                  </div>
  
                  <!-- Job Skills -->
                </div>
  
                <div class="sidebar-widget contact-widget">
                  <h4 class="widget-title">Contact Us</h4>
                  <div class="widget-content">
                    <!-- Comment Form -->
                    <div class="default-form">
                      <!--Comment Form-->
                      <form>
                        <div class="row clearfix">
                          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <input
                              type="text"
                              name="username"
                              placeholder="Your Name"
                              required
                            />
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <input
                              type="email"
                              name="email"
                              placeholder="Email Address"
                              required
                            />
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <textarea
                              class="darma"
                              name="message"
                              placeholder="Message"
                            ></textarea>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                            <button
                              class="theme-btn btn-style-one"
                              type="submit"
                              name="submit-form"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  import Constant from "@/interceptor/Constant.js";
  import vcookie from "vue-cookie";

  
  export default {
    data() {
      return {
        course: {},
        imageurl: Constant.filebaseurl + "image/university/",
        university:{},
        program :{},
      };
    },
  
    methods: {
      getCourse(id) {
        axios
          .get("/api/web/course/" + id)
          .then((response) => {
              this.course = response.data.data;
              this.university = this.course.university
              this.program = this.course.program
            
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
      apply(id,program_id,university_id){

if(vcookie.get("user") != null){

  if(JSON.parse(vcookie.get("user")).role == "student"){

axios.post("api/web/application",{
user_id : JSON.parse(vcookie.get("user")).id,
university_id : university_id,
course_id : id,
program_id : program_id,
description : "description"


}).then((res) => {

if(res){
  // this.$emit("get_message", "Applied to Course Successfully");
     
  this.$router.push({ name: "application" , query: {  application_id: res.data.data.id } });

}

}).catch((error) => {
 console.log(error.response);
});




    
  }else{

  var route_name_stu = this.$route.name



   this.$router.push({ name: "login" , query: { route_name: route_name_stu, id: this.course_id } });



  }



  
}else{

  var route_name = this.$route.name





this.$router.push({ name: "login" , query: { route_name: route_name, id: this.course_id } });




}




},
    },
  
    mounted() {
      var id = this.$route.query.id;
      this.course_id = id
  
      if (id != null) {
        this.getCourse(id);
        window.scrollTo(0, 0);
      }
    },
  };
  </script>
  
  